export const useSingleAirVisibility = defineStore('singleAirVisibilityStore', {
  state: () => ({
    bookmark: null,
    isLoading: true,
  }),
  actions: {
    setBookmarkData(bookmark: any) {
      this.bookmark = bookmark;
      this.isLoading = false;
    },
  },
});
